.request-phone .PhoneInputInput {
  border: 1px solid var(--na-primary-color);
  height: 40px;
}

.header-border-shadow {
  box-shadow:
    0 4px 6px -1px #00000040,
    0 2px 4px -1px #0000000f;
}
.meal-request-text .see-more-toggle {
  display: none;
}
@media (max-width: 768px) {
  .meal-request-text .see-more-toggle {
    display: inline-block;
    background: none;
    border: none;
    color: var(--na-primary-color);
    text-decoration: underline;
    cursor: pointer;
  }
  .meal-request-text .text-content:not(.show-full) {
    display: block;
  }
  .meal-request-text .text-content:not(.show-full) p:nth-child(n + 1) {
    display: none;
  }
  .meal-request-text .text-content.show-full p {
    display: block;
  }
}
@media (min-width: 769px) {
  .meal-request-text .text-content {
    display: block;
  }
  .meal-request-text .see-more-toggle {
    display: none;
  }
}

.meal-matching-card {
  border: 1px solid var(--na-family-member);
  margin-bottom: 20px;
}

.shadow-component {
  box-shadow: 0px -1px 5px 1px rgba(0, 0, 0, 0.1);
}
.request-matching-input:focus {
  border: 1px solid var(--na-family-member-dark);
  outline: none;
}

.meal-request-mobile .PhoneInputInput {
  border: none;
  background-color: var(--na-tertiary-color-lite);
  height: 35px;
  padding: 5px;
  padding-left: 10px;
  border-radius: 20px;
}
.meal-request-mobile .PhoneInputInput:focus {
  border: 1px solid var(--na-family-member-dark);
  outline: none;
}
.matching-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.gm-style-iw-chr {
  display: none;
}
.gm-style-iw-d {
  height: 100% !important;
  width: 100% !important;
  overflow: auto !important;
}
.gm-style .gm-style-iw-c {
  padding-inline-start: 0px;
}
.gm-style-iw-d {
  max-height: 350px !important;
}
.gm-style .gm-style-iw-c {
  max-height: 350px !important;
}
.requestor-top {
  background: rgb(255, 155, 107);
  background: linear-gradient(
    90deg,
    rgba(255, 155, 107, 1) 0%,
    rgba(255, 155, 107, 1) 35%,
    rgba(241, 179, 54, 1) 100%
  );
}
