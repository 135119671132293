.th-bg-image {
  background-image: url(../images/frame-bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.lasagna-theme .avatar > div {
  display: flex;
  aspect-ratio: 0;
}
.th-container {
  width: 95%;
  margin: 0 auto;
  min-height: 85vh;
}
.breadcrumbs ul li {
  color: var(--na-primary-color);
}
.nav li {
  padding: 0.65rem 1rem;
  border-bottom: 1px solid var(--na-lite-gray2);
}
.nav li.active,
.nav li:hover {
  background-color: var(--na-primary-lite);
}
.nav li.active a {
  font-weight: 700;
}
.nav li a {
  color: var(--theme-gray);
  font-size: 0.875rem;
  font-weight: 500;
  width: 100%;
  height: 100%;
  display: block;
}
.border-b-primary {
  border-bottom: 1px solid var(--na-primary-color-hover);
}

.main-nav-sec ul {
  display: flex;
  column-gap: 40px;
}
.main-nav-sec ul li a, .main-nav-sec ul li button {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
}
.main-nav-sec ul li span {
  width: 2.25rem;
  height: 2.25rem;
  border: 1px solid var(--na-primary-color);
  padding: 0.25rem;
  border-radius: 100%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-nav-sec ul li img,
.main-nav-sec ul li svg {
  width: 100%;
}
.main-nav-sec ul li:hover span,
.main-nav-sec ul li.active span {
  background-color: var(--na-primary-color);
}
.main-nav-sec ul li:hover img,
.main-nav-sec ul li:hover svg,
.main-nav-sec ul li.active img,
.main-nav-sec ul li.active svg {
  filter: brightness(100);
}

.navbar {
  background-repeat: no-repeat;
  background-position: right;
  background-size: 160px;
}
.navbar.volunteer-head {
  background-image: url(../vectors/volunteer-header-art.svg);
}
.navbar.volunteer-head .profile-block .profile-icon {
  border: 5px solid #d29847;
}
.navbar .menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul).active),
.navbar
  .menu
  :where(li:not(.menu-title):not(:empty))
  > :where(:not(ul):active) {
  background-color: var(--na-primary-color);
}
.rotate-img {
  transform: rotate(180deg);
}

@media (max-width: 1023px) {
  .navbar {
    background-size: 130px;
  }
  .main-nav-sec ul {
    display: block;
    column-gap: 40px;
  }
  .main-nav-sec {
    position: absolute;
    left: 0;
    top: 79px;
    background-color: #fff;
    width: 100%;
    padding: 10px;
    z-index: 9;
    box-shadow: 0px 7px 10px #dcdcdc;
    border-radius: 0px 0px 10px 10px;
    display: none;
  }
  .main-nav-sec ul li {
    margin: 0.875rem 0rem;
  }
  .expand,
  .sideNav-toggle {
    display: block !important;
  }
  .breadcrumbs ul li {
    font-size: 0.75rem;
  }
}
.header-menu {
  color: var(--na-primary-color);
}
