.schedule-card-header {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  border-radius: 14px 14px 0 0;
  height: 36px;
  top: 0;
  left: 0;
  padding: 7px 4px;
  font-weight: 400;
}

.week-available-toggle:checked {
  color: var(--na-primary-color-hover) !important;
}

.volunteer-icon {
  color: var(--na-primary-color-active);
}

.family-icon {
  color: var(--na-family-member);
}

.volunteer-close {
  color: var(--na-primary-color-active);
}

.family-close {
  color: var(--na-family-member);
}

.family-card-arrow {
  color: var(--na-family-member);
}

.location-volunteer {
  color: var(--na-primary-color);
}

.location-family {
  color: var(--na-family-member);
}

.profile-sidebar {
  color: white;
}

.user-icon {
  color: var(--na-secondary-color-hover);
}

.user-icon-profile {
  background-color: var(--na-primary-color);
}
.profile-card-border {
  border-radius: 6px 0px 0px 6px;
}

.pause-untill-style {
  height: 2.5rem;
  width: 9rem;
}

@media (min-width: 1200px) {
  .border-right::after {
    position: absolute;
    content: '';
    width: 1px;
    height: 80px;
    top: 50%;
    right: 0px;
    background-color: var(--na-gray3);
  }
}
