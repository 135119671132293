.base-layout-height {
  min-height: calc(100vh - 145px);
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.faq-answer.expanded {
  max-height: 200px;
  overflow: hidden;
  transition: max-height 0.5s ease-in;
}

@media (max-width: 767px) {
  .faq-answer.expanded {
    overflow: auto;
  }
}

.arrow {
  transition: transform 0.3s;
}

.arrow.up {
  transform: rotate(180deg);
}

.faq-editor .ql-container {
  height: 200px;
}

.rotate-side {
  top: 131px !important;
  left: -27px !important;
}
.rotate-side-family {
  top: 120px !important;
  left: -44px !important;
}
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1);
}

.resource-tab .text-link {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  margin-right: 10px;
}
.resource-tab {
  overflow-x: scroll;
  height: 80px;
  align-items: start;
  overflow-y: hidden;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1);
}

.resource-tab .tab-active {
  height: 50px !important;
}
