@font-face {
  font-family: MotelCalifornia;
  src: url(../fonts/MotelCalifornia.otf);
}

body {
  font-family: var(--na-primary-font) !important;
}
.secondary-font {
  font-family: var(--na-secondary-font);
}
/* Main Heading */
h1 {
  font-size: 1.5rem !important;
  font-family: var(--na-secondary-font);
} /* 24px */
/* Main Heading */
h2 {
  font-size: 1.375rem !important;
} /* 22px */
h3 {
  font-size: 1.125rem !important;
} /* 18px */
h4 {
  font-size: 1rem !important;
} /* 16px */
h5 {
  font-size: 0.875rem !important;
} /* 14px */
h6 {
  font-size: 0.75rem !important;
} /* 12px */
a {
  line-height: normal;
  cursor: pointer;
}
p.sm {
  font-size: 0.875rem;
} /* 14px */

/* COLORS  */
.color-primary {
  color: var(--na-primary-color);
}
.color-secondary {
  color: var(--na-secondary-color);
}
.color-secondary-hover {
  color: var(--na-secondary-color-hover);
}
.color-primary-hover {
  color: var(--na-primary-color-hover);
}
.color-gray {
  color: var(--na-gray);
}
.color-red {
  color: var(--na-red);
}
.border-primary-color {
  border: 1px solid var(--na-primary-color) !important;
}

.border-primary-color-hover {
  border: 1px solid var(--na-primary-color-hover) !important;
}

.bg-primary-color {
  background-color: var(--na-primary-color-hover);
}

.bg-tertiary {
  background-color: var(--na-tertiary-color-lite);
}

.primary-color {
  color: var(--na-primary-color);
}

.secondary-color {
  color: var(--na-secondary-color);
}

.primary-color-hover {
  color: var(--na-primary-color-hover);
}

.secondary-color-hover {
  color: var(--na-secondary-color-hover);
}

.na-secondary-color-hover {
  color: var(--na-secondary-color-hover);
}

.family-member-color {
  color: var(--na-family-member);
}

.border-family-member {
  border: 1px solid var(--na-family-member) !important;
}

.primary-color-active {
  color: var(--na-primary-color-active);
}

.primary-color-grey-4 {
  color: var(--na-lite-gray4);
}
.border-primary-color-active {
  border: 1px solid var(--na-primary-color-active);
}

.bg-family-member {
  background-color: var(--na-family-member);
}

.border-primary {
  border: 1px solid var(--na-primary-color);
}

.theme-lite-gray {
  color: var(--theme-lite-gray4);
}

.theme-primary-color-active {
  border: 1px solid var(--na-primary-color-active);
  color: var(--na-primary-color-active);
}

.bg-primary-lite {
  background-color: var(--na-primary-lite);
}

/* COMMON FOR ALL BUTTONS  */
.common-button {
  border-radius: 1.875rem;
  padding: 0rem 0.9rem;
  font-size: 0.875rem;
  font-weight: 600;
  min-height: 2.625rem;
  font-family: var(--na-primary-font);
  word-break: keep-all;
  white-space: nowrap;
}
.common-button[disabled],
.common-button[disabled]:hover,
.common-button[disabled]:active {
  background-color: #f6f6f6;
  color: var(--na-lite-gray3);
  cursor: not-allowed;
  border: 1px solid #f6f6f6;
}
.common-button[disabled] img,
.common-button[disabled] svg {
  opacity: 0.25;
  filter: brightness(0.5);
}

/* THEME 1 BUTTONS  */
.button-primary {
  background-color: var(--na-primary-color);
  color: var(--na-white);
  border: none;
  border: 1px solid var(--na-primary-color);
  outline: none;
  cursor: pointer;
}
.button-outline-filled {
  background-color: var(--na-primary-color-hover);
  color: var(--na-white);
  border: 1px solid var(--na-primary-color);
}
.button-primary:hover {
  background-color: var(--na-primary-color-hover);
  border: 1px solid var(--na-primary-color-hover);
}
.button-primary:active {
  background-color: var(--na-primary-color-active);
}

/* THEME 2 BUTTONS  */
.button-secondary {
  background-color: var(--na-secondary-color);
  color: var(--na-white);
  border: none;
  border: 1px solid var(--na-secondary-color);
  outline: none;
  cursor: pointer;
}
.button-secondary:hover {
  background-color: var(--na-secondary-color-hover);
}
.button-secondary:active {
  background-color: var(--na-secondary-color-hover);
}

/* THEME 1 BUTTONS TRANSPARENT  */
.primary-outline {
  background-color: transparent;
  color: var(--na-primary-color);
  border: 1px solid var(--na-primary-color);
  outline: none;
  cursor: pointer;
}
.primary-outline:hover {
  background-color: transparent;
  border: 1px solid var(--na-primary-color-hover);
  color: var(--na-primary-color-hover);
}
.primary-outline:active {
  background-color: transparent;
  border: 1px solid var(--na-primary-color-active);
  color: var(--na-primary-color-active);
}

/* THEME 2 BUTTONS TRANSPARENT  */
.secondary-outline {
  background-color: transparent;
  color: var(--na-secondary-color);
  border: 1px solid var(--na-secondary-color);
  outline: none;
  cursor: pointer;
}
.secondary-outline:hover {
  background-color: transparent;
}
.secondary-outline:active {
  background-color: transparent9;
}

/* BUTTONS  WITH IMAGE (AFTER) */
.after-icon-button {
  display: flex;
  align-items: center;
}
.after-icon-button img,
.icon-button svg {
  margin-left: 0.625rem;
  width: 1.25rem;
}

/* BUTTONS  WITH IMAGE (BEFORE) */
.before-icon-button {
  display: flex;
  align-items: center;
}
.before-icon-button img,
.icon-button svg {
  margin-right: 0.625rem;
  width: 1.25rem;
}

/* TRANSPARENT LINK WITH IMG */
.transparent-link {
  display: flex;
  align-items: center;
}
.transparent-link img {
  margin-right: 0.3rem;
}

/* CHECKBOX - COMMON */
input[type='checkbox'].th-checkbox {
  position: relative;
  border-radius: 0.25rem;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 0.6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  -webkit-appearance: none;
  opacity: 0.5;
}
input[type='checkbox'].th-checkbox:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 0.25rem;
  height: 0.625rem;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  margin: -1px 0px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}
input[type='checkbox'].th-checkbox[disabled] {
  background-color: var(--na-lite-gray);
  border: 1px solid var(--na-lite-gray) !important;
}

/* CHECKBOX - PRIMARY */
input[type='checkbox'].th-checkbox.primary {
  border: 1px solid var(--na-primary-color);
}
input[type='checkbox'].th-checkbox.primary:checked {
  background-color: var(--na-primary-color);
  border: 1px solid var(--na-primary-color);
  opacity: 1;
}
input[type='checkbox'].th-checkbox.primary:hover {
  opacity: 1;
}

/* CHECKBOX - SECONDARY */
input[type='checkbox'].th-checkbox.secondary {
  border: 1px solid var(--na-lite-gray);
}
input[type='checkbox'].th-checkbox.secondary:checked {
  background-color: var(--na-secondary-color);
  border: 1px solid var(--na-secondary-color);
  opacity: 1;
}
input[type='checkbox'].th-checkbox.secondary:hover {
  opacity: 1;
}

/* CHECKBOX - SIZES */
input[type='checkbox'].th-checkbox.l {
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
}
input[type='checkbox'].th-checkbox.l:before {
  width: 0.188rem;
  min-width: 0.188rem;
  height: 0.5rem;
}
input[type='checkbox'].th-checkbox.xl {
  height: 1.25rem;
  width: 1.25rem;
  min-width: 1.25rem;
}
input[type='checkbox'].th-checkbox.xl:before {
  width: 0.25rem;
  min-width: 0.25rem;
  height: 0.7rem;
}
input[type='checkbox'].th-checkbox.xl2 {
  height: 1.875rem;
  width: 1.875rem;
  min-width: 1.875rem;
}
input[type='checkbox'].th-checkbox.xl2:before {
  width: 7px;
  min-width: 5px;
  height: 15px;
}

input[type='checkbox'].th-checkbox.primary {
  background-color: #fff;
}
input[type='checkbox'].th-checkbox.primary:checked::before {
  opacity: 1;
}
input[type='checkbox'].th-checkbox.primary:before {
  opacity: 0;
}

input.border-primary:focus {
  outline: none !important;
}

/* CHECKBOX - COMMON */
[type='radio'].th-radio:checked,
[type='radio'].th-radio:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio'].th-radio:checked + label,
[type='radio'].th-radio:not(:checked) + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  line-height: 1.4rem;
  display: inline-block;
  color: var(--na-primary-color-dark);
}
[type='radio'].th-radio:checked + label:before,
[type='radio'].th-radio:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--na-lite-gray);
  border-radius: 100%;
  background: var(--na-white);
}
[type='radio'].th-radio:checked + label:after,
[type='radio'].th-radio:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio'].th-radio:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio'].th-radio:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
[type='radio'].th-radio:checked + label:after,
[type='radio'].th-radio.primary:not(:checked) + label:after {
  background: var(--na-lite-gray);
}
[type='radio'].th-radio:checked + label:before {
  border: 2px solid var(--na-lite-gray);
  background: var(--na-white);
}

/* CHECKBOX - PRIMARY */
[type='radio'].th-radio.primary:checked + label:after,
[type='radio'].th-radio.primary:not(:checked) + label:after {
  background: var(--na-primary-color);
}
[type='radio'].th-radio.primary:checked + label:before {
  border: 2px solid var(--na-primary-color);
  background: var(--na-white);
}

/* CHECKBOX - SECONDARY */
[type='radio'].th-radio.secondary:checked + label:after,
[type='radio'].th-radio.secondary:not(:checked) + label:after {
  background: var(--na-secondary-color);
}
[type='radio'].th-radio.secondary:checked + label:before {
  border: 2px solid var(--na-secondary-color);
  background: var(--na-white);
}

/* CHECKBOX - DISABLED */
[type='radio'].th-radio[disabled]:checked + label:after,
[type='radio'].th-radio[disabled]:not(:checked) + label:after {
  background: var(--na-primary-color);
  cursor: not-allowed;
}
[type='radio'].th-radio[disabled]:checked + label:before {
  border: 2px solid var(--na-lite-gray);
  background: var(--na-lite-gray3);
  cursor: not-allowed;
}
[type='radio'].th-radio[disabled]:checked + label:before,
[type='radio'].th-radio[disabled]:not(:checked) + label:before {
  background: #ffffff;
  cursor: not-allowed;
}

[type='radio'].th-radio[disabled]:checked + label:before {
  border: 2px solid var(--na-primary-color);
}

/* TOGGLE - COMMON */
.th-toggle {
  display: flex;
  align-items: center;
  height: 16px;
}
.th-toggle span {
  padding-left: 8px;
  font-size: 0.95rem;
}
.th-toggle input[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
}
.th-toggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 53px;
  height: 24px;
  background: var(--na-gray1);
  display: block;
  border-radius: 100px;
  position: relative;
}
.th-toggle label:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.th-toggle label:active:after {
  width: 20px;
}
.th-toggle input[disabled] + label:after {
  background: var(--na-gray1);
  opacity: 0.7;
}
.th-toggle input[disabled] + label {
  background: var(--na-gray2);
}

/* TOGGLE - PRIMARY */
.th-toggle.primary input:checked + label {
  background: var(--na-white);
  border: 1px solid var(--na-white);
}
.th-toggle.primary label {
  background: var(--na-white);
  border: 1px solid var(--na-primary-color);
}
.th-toggle.primary label:after {
  background: var(--na-primary-color);
  top: 2px;
}
.th-toggle input:checked + label:after {
  background: var(--na-accept-green);
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

/* TOGGLE - SECONDARY */
.th-toggle.secondary input:checked + label {
  background: var(--na-secondary-color);
}
.th-toggle.secondary label {
  background: var(--na-white);
  border: 1px solid var(--na-secondary-color);
}
.th-toggle.secondary label:after {
  background: var(--na-secondary-color);
  top: 2px;
}

/* TOGGLE WITH TEXT */

.th-toggle.withText.primary input + label {
  text-indent: 1px;
  display: flex;
  align-items: center;
  padding-left: 7px;
  font-size: 11px;
  font-weight: bold;
  padding-right: 5px;
  justify-content: end;
}
.th-toggle.withText.primary input:checked + label {
  justify-content: start;
  color: #fff;
  border: 1px solid var(--na-accept-green);
}
.th-toggle.withText.primary input:checked + label:before {
  content: 'Yes';
  color: var(--na-accept-green);
}
.th-toggle.withText.primary input + label:before {
  content: 'No';
  color: var(--na-primary-color);
}

/* BADGES COMMON */
.th-badge {
  background-color: var(--na-lite-gray3);
  padding: 6px 17px;
  border-radius: 40px;
  font-size: 0.85rem;
  font-weight: 500;
  margin: 0px 6px;
  margin-bottom: 6px;
}

/* BADGE CRITICAL */
.th-badge.status-critical {
  background-color: var(--na-primary-color);
  color: var(--na-white);
  border: 1px solid var(--na-primary-color);
}

/* BADGE CRITICAL TRANSPARENT */
.th-badge.status-critical-transparent {
  background-color: var(--na-white);
  color: var(--na-primary-color);
  border: 1px solid var(--na-primary-color);
}

/* BADGE Dark */
.th-badge.status-dark {
  background-color: var(--na-secondary-color-hover);
  color: var(--na-white);
  border: 1px solid var(--na-secondary-color);
}

/* BADGE Dark TRANSPARENT */
.th-badge.status-dark-transparent {
  background-color: var(--na-white);
  color: var(--na-secondary-color);
  border: 1px solid var(--na-secondary-color-hover);
}

/* BADGE WARNING */
.th-badge.status-warning {
  background-color: var(--na-warning-lite);
  color: var(--na-warning);
  border: 1px solid var(--na-warning);
}

/* BADGE SUCCESS */
.th-badge.status-success {
  background-color: var(--na-success-lite);
  color: var(--na-success);
  border: 1px solid var(--na-success);
}

/* BADGE COMPLETE */
.th-badge.status-complete {
  background-color: var(--na-green-light);
  color: var(--na-green-dark);
  border: 1px solid var(--na-green-dark);
}

/* BADGE HOLD */
.th-badge.status-hold {
  background-color: var(--na-wait-lite);
  color: var(--na-wait);
  border: 1px solid var(--na-wait);
}

/* BADGE CANCELLED */
.th-badge.status-cancelled {
  background-color: var(--na-critical-lite);
  color: var(--na-critical);
  border: 1px solid var(--na-critical);
}

/* BADGE TAGS */
.th-badge.status-tags {
  background-color: var(--na-primary-color-hover);
  color: var(--na-white);
  border: 1px solid var(--na-primary-color);
}

/* WARNING MESSAGE */
span.th-badge.warning-message {
  background-color: var(--na-warning-lite);
  color: var(--na-secondary-color);
  border: 1px solid var(--na-warning);
  display: flex;
  column-gap: 10px;
  border-radius: 5px;
}

/* SUCCESS MESSAGE */
span.th-badge.success-message {
  background-color: var(--na-success-lite);
  color: var(--na-secondary-color);
  border: 1px solid var(--na-success);
  display: flex;
  column-gap: 10px;
  border-radius: 5px;
}

/* ERROR MESSAGE */
span.th-badge.error-message {
  background-color: var(--na-critical-lite);
  color: var(--na-secondary-color);
  border: 1px solid var(--na-critical);
  display: flex;
  column-gap: 10px;
  border-radius: 5px;
  z-index: 99;
}

/* INFO MESSAGE */
span.th-badge.info-message {
  background-color: var(--na-info-lite);
  color: var(--na-secondary-color);
  border: 1px solid var(--na-info);
  display: flex;
  column-gap: 10px;
  border-radius: 5px;
}

/* INPUT BOX */
.th-form-control {
  width: 100%;
  display: block;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  padding: 0rem 0.6rem 0rem 1rem;
  outline: none;
}
.th-form-control::placeholder {
  color: var(--na-secondary-color-hover);
  opacity: 1;
}
.th-form-control::-ms-input-placeholder {
  color: var(--na-secondary-color-hover);
}

.th-form-control.lg {
  height: 3rem;
  font-size: 1rem;
}

.th-form-control.default {
  border: 1px solid var(--na-lite-gray1);
  border-radius: 0.25rem;
  min-height: 35px;
  font-size: 0.85rem;
}

.th-form-control.primary {
  border: 1px solid var(--na-lite-gray2);
  border-radius: 10rem;
  min-height: 35px;
  background-color: var(--na-lite-gray2);
}

.th-form-control.secondary {
  border: 1px solid var(--na-primary-lite);
  border-radius: 10rem;
  min-height: 35px;
  background-color: var(--na-primary-lite);
  color: var(--na-secondary-color-hover);
}

.th-form-control[disabled] {
  background-color: var(--na-lite-gray2);
  color: var(--na-lite-gray3);
  cursor: not-allowed;
  border: 1px solid var(--na-gray2);
}

.th-form-control.default-transparent {
  border: 1px solid var(--na-primary-color-hover);
  border-radius: 0.35rem;
  min-height: 35px;
  background-color: var(--na-white);
}

.th-form-control.primary-outline {
  border: 1px solid var(--na-primary-color-hover);
  border-radius: 30rem;
  min-height: 35px;
  background-color: var(--na-white);
}

.th-form-control.secondary-outline {
  border: 1px solid var(--na-primary-color-hover);
  border-radius: 30rem;
  min-height: 35px;
  background-color: var(--na-primary-lite);
}

textarea.th-form-control.primary {
  border-radius: 1.3rem;
  font-size: 1rem;
  padding: 1rem 1.5rem;
}

.textarea:disabled,
.textarea[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border: 1px solid var(--na-primary-color) !important;
  --tw-bg-opacity: 1;
  background-color: var(--na-disabled);
  color: var(--na-black);
}
.select:disabled,
.th-form-control:disabled {
  background-color: var(--na-disabled);
  color: var(--na-black);
}

@media (max-width: 991px) {
  .th-form-control.lg {
    height: 3rem;
  }
}
/* STEP BY STEP COUNTER */
.progress-steps .step-counter {
  display: inline-grid;
  grid-auto-flow: column;
  overflow: hidden;
  overflow-x: auto;
  counter-reset: step;
  grid-auto-columns: 1fr;
}
.step-counter .count {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-columns: auto;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-rows: 40px 1fr;
  place-items: center;
  text-align: center;
  min-width: 4.5rem;
}
.step-counter .count:after {
  content: counter(step);
  counter-increment: step;
  z-index: 1;
  position: relative;
  grid-column-start: 1;
  grid-row-start: 1;
  display: grid;
  height: 2rem;
  width: 2rem;
  place-items: center;
  place-self: center;
  border-radius: 9999px;
  background-color: var(--na-white);
  border: 1px solid var(--na-primary-color);
  color: var(--na-primary-color);
}
.step-counter .count.completed:after {
  background-color: var(--na-primary-color);
  color: var(--na-white);
}
.step-counter .count:before {
  top: 0;
  grid-column-start: 1;
  grid-row-start: 1;
  height: 0.05rem;
  width: 100%;
  background-color: var(--na-primary-color);
  content: '';
  margin-left: -100%;
}
.step-counter .count:first-child:before {
  content: none;
}
.error-msg {
  color: var(--na-critical);
}
.sidebar-scrollbar::-webkit-scrollbar {
  width: 5px;
}
.sidebar-scrollbar::-webkit-scrollbar-track {
  background: var(--na-primary-color);
}
.sidebar-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 50px;
}
.sidebar-scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--theme-gray);
}

.card-shadow {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
}
.tabs {
  border-bottom: 1px solid var(--na-primary-color-hover);
}
.tabs .tab {
  color: var(--na-primary-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  padding-left: 8px;
}
.tabs .tab.tab-active {
  color: var(--na-primary-color);
  font-weight: 700;
  border-bottom: 3px solid var(--na-primary-color-active) !important;
}
.context-menu-wrapper .dropdown-content {
  visibility: visible !important;
  opacity: 1 !important;
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  transform: none !important;
  right: 0px;
  top: 28px;
}
.confirmation-box-container {
  width: 100%;
  max-width: 600px;
  position: relative;
}

.confirmation-box-container .py-5 {
  padding-bottom: 0px;
  padding-top: 0px;
}

@media (max-width: 767px) {
  .confirmation-box-container {
    width: 88%;
  }
}
.input-search::placeholder {
  color: var(--na-primary-color-hover-lite);
  opacity: 1;
}

.input-search::-ms-input-placeholder {
  color: var(--na-primary-color-hover-lite);
}

.tooltip {
  position: relative;
}

.tooltip:hover .tooltip-content {
  display: block;
}

.tooltip-content {
  display: none;
  position: absolute;
  background-color: #000;
  color: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  z-index: 1000;
  white-space: nowrap;
}

/* ----------custom scrollbar------ */

.custom-scrollbar {
  scrollbar-color: auto;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 7px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: var(--na-gray-100);
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: var(--na-gray-400);
  border-radius: 30px;
}

.tabs.area-card button {
  width: 50%;
  text-align: center;
  justify-content: center;
}

/* custom style for volunteer matches */
.allergy-info-section .allergy-info-text-area {
  min-height: 134px !important;
}

.list-grid-view-icon-wrapper {
  width: 65% !important;
}

.tabs-selection {
  width: 100% !important;
}
.locationInfo {
  color: #fff !important;
}

@media (max-width: 767px) {
  .tabs.area-card {
    width: 100% !important;
  }
}

.toggle:checked,
.toggle[aria-checked='true'] {
  color: oklch(0.63 0.16 43.03);
}

svg.view {
  border: 1px solid transparent !important;
  width: 40px;
  height: 40px;
  padding: 7px;
  border-radius: 6px;
}
svg.view.active-view {
  border: 1px solid #ddd !important;
}

.zipcode-close {
  top: -5px;
}

.custom-alert-link {
  color: var(--na-primary-color);
}

.active-stack-item {
  background-color: grey !important;
}

.striped-table tbody tr:nth-child(even) {
  @apply bg-[var(--na-primary-color-active-lite)];
}

.striped-table tbody td {
  color: #000000 !important;
}

.fixed-table-layout {
  overflow-x: auto !important;
}
.fixed-table-layout table {
  table-layout: fixed;
}
.fixed-table-layout-2-col table th,
.fixed-table-layout-2-col table td {
  width: 50%;
}

.manual-matching-widget {
  .tooltip {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .tooltip-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: gray;
  }

  .tooltip iframe {
    border-radius: 8px;
    overflow: hidden;
  }
}

@media (min-width: 1440px) {
  .w-half {
    width: 50%;
  }
}

.timezone-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .fixed-table-layout {
    overflow-x: scroll;
  }
  .fixed-table-layout-2-col table th,
  .fixed-table-layout-2-col table td {
    width: 220px;
  }
}

.border-accept-color {
  border: 1px solid var(--na-accept-green) !important;
}

.edit-request-form {
  overflow-y: scroll;
  height: 560px;
}
