.download-btn {
  background: var(--na-primary-color);
}

.download-center-inactive-btn {
  background-color: var(--na-primary-color-active-lite);
  color: var(--na-white);
}

.modal-popup-panel-container {
  width: 100%;
  max-width: 400px;
  position: relative;
}

@media (max-width: 767px) {
  .modal-popup-panel-container {
    width: 75%;
    max-width: 400px;
    position: relative;
  }
}
